import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HeadSearchView } from '../models/head-search-view.model';
import { map, Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeadsQueryService {

  heads: HeadSearchView[] | undefined = undefined;
  offset = 0;
  totalCount?: number = undefined;

  private queryLabels: string[] = [];
  private queryStates: string[] = ['included'];

  constructor(private api: ApiService) {
  }

  getQueryLabels(): string[] {
    return this.queryLabels;
  }

  setQueryLabels(labels: string[]) {
    this.queryLabels = labels;
  }

  getQueryStates(): string[] {
    return this.queryStates;
  }

  setQueryStates(states: string[]) {
    this.queryStates = states;
  }

  refresh(): Observable<HeadSearchView[]> {
    this.offset = 0;
    this.heads = [];
    this.totalCount = undefined;
    return this.loadMore();
  }

  loadMore(): Observable<HeadSearchView[]> {
    return this.api.getHeads(this.queryStates, this.queryLabels, this.offset, 100).pipe(
      tap(response => {
        this.totalCount = response.total_count;
        this.offset += response.items.length;
        if (this.heads === undefined) {
          this.heads = response.items;
        } else {
          this.heads = this.heads.concat(response.items);
        }
      }),
      map(response => {
        return response.items;
      })
    );
  }
}
