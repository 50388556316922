/**
 * Format a size in bytes to a human-readable string with an appropriate unit (e.g. '54.2 MB').
 *
 * @param sizeBytes The size in bytes
 */
export function formatSize(sizeBytes: number): string {
  const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
  let i;
  for (i = 0; sizeBytes > 1024; i++) {
    sizeBytes /= 1024;
  }
  return sizeBytes.toFixed(1) + ' ' + units[i];
}
