import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

/**
 * Interceptor that handles 401/403 responses from the backend.
 */
@Injectable()
export class AuthenticationErrorInterceptor implements HttpInterceptor {

  constructor(private router: Router,
              private auth: AuthService) {
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    if (err.status === 401) {
      console.log(`Received HTTP 401, clearing stored authentication and showing login page`);
      this.auth.signOut();
      this.router.navigate([`/login`]);
      return throwError(() => err);
    }

    if (err.status === 403) {
      console.warn(`Received HTTP 403, clearing stored authentication and showing login page`);
      // TODO: not authorized page
      this.auth.signOut();
      this.router.navigate([`/login`]);
      return throwError(() => err);
    }

    return throwError(() => err);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(x => this.handleAuthError(x))
    );
  }
}
