import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GalleryComponent } from './components/gallery/gallery.component';
import { AuthGuard } from './guards/auth.guard';
import { DetailComponent } from './components/detail/detail.component';
import { DeliverComponent } from './components/deliver/deliver.component';
import { LoginComponent } from './components/login/login.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'heads',
    component: GalleryComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'heads/:id',
    component: DetailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'deliver',
    component: DeliverComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: 'heads'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
