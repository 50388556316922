import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { Head } from '../models/head.model';
import { environment } from '../../environments/environment';
import { BatchJob } from '../models/batch-job.model';
import { HeadSearchView } from '../models/head-search-view.model';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) {
  }

  accessToken(username: string, password: string): Observable<string> {
    return this.http.post<{ access_token: string }>(`${environment.apiUrl}/api/v1/auth/token`, {
      username: username,
      password: password
    }).pipe(map(r => r.access_token));
  }

  getHead(id: string): Observable<Head> {
    return this.http.get<Head>(`${environment.apiUrl}/api/v1/heads/${id}`);
  }

  getHeads(states: string[], labels: string[], offset: number = 0, limit: number = 50): Observable<PagedResponse<HeadSearchView>> {
    const stateList = states.join(',');
    const labelsList = labels.join(',');
    return this.http.get<PagedResponse<HeadSearchView>>(
      `${environment.apiUrl}/api/v1/heads?state=${stateList}&labels=${labelsList}&offset=${offset}&limit=${limit}`);
  }

  getHeadOriginalDownloadUrl(id: string): Observable<string> {
    return this.http.get<{ url: string }>(`${environment.apiUrl}/api/v1/heads/${id}/original_download_url`)
      .pipe(map(r => r.url));
  }

  includeHead(id: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/v1/heads/${id}/include`, null);
  }

  excludeHead(id: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/v1/heads/${id}/exclude`, null);
  }

  deliver(url: string): Observable<string> {
    return this.http.post<{ job_id: string }>(`${environment.apiUrl}/api/v1/deliver`, {
      url: url
    }).pipe(map(r => r.job_id));
  }

  validateDeliverUrl(url: string): Observable<string | null> {
    return this.http.post<{ message: string }>(`${environment.apiUrl}/api/v1/deliver/validate_url`, {
      url: url
    }).pipe(map(r => r.message === 'ok' ? null : r.message));
  }

  getActiveBatchJobs(): Observable<BatchJob[]> {
    return this.http.get<BatchJob[]>(`${environment.apiUrl}/api/v1/batch/jobs`);
  }
}
