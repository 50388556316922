<div class="login-container">
  <main class="container">
    <article class="grid">
      <div>
        <div class="headings">
          <h1>Sign in</h1>
          <h2>Please sign in with your username and password</h2>
        </div>
        <form [formGroup]="loginForm">
          <input type="text" name="login" placeholder="Login" aria-label="Login" autocomplete="nickname" required
                 formControlName="username">
          <input type="password" name="password" placeholder="Password" aria-label="Password"
                 autocomplete="current-password" required formControlName="password">
          <button [disabled]="!loginForm.valid" type="submit" class="contrast" (click)="doLogin()">Login</button>
          <p class="error" *ngIf="errorMessage">{{ errorMessage }}</p>
        </form>
      </div>
      <div class="login-hero-image"></div>
    </article>
    <p class="version-number" style="text-align: right">
      <small>Version: {{ versionNumber }}</small>
    </p>
  </main>
</div>

