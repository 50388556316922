import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { DetailViewModel, TimestampedBoundingBox } from '../../viewmodels/detail.viewmodel';
import { firstValueFrom } from 'rxjs';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {

  id?: string;
  busy = false;
  viewModel?: DetailViewModel;
  idCopiedRecently = false;
  metadataCopiedRecently = false;
  faceDataCopiedRecently = false;
  drawBoundingBoxes = false;

  @ViewChild('video') videoElement: ElementRef<HTMLVideoElement> | undefined;
  @ViewChild('overlay') overlayElement: ElementRef<HTMLCanvasElement> | undefined;

  constructor(private api: ApiService,
              private auth: AuthService,
              private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.viewModel = undefined;
    this.route.params.subscribe((params) => {
      this.id = params['id'];
      if (this.id) {
        this.refresh();
      } else {
        this.goBack();
      }
    });
  }

  goBack(): void {
    this.router.navigate(['/heads'], { replaceUrl: true });
  }

  copyIdToClipboard(): void {
    if (this.viewModel?.id) {
      navigator.clipboard.writeText(this.viewModel.id).then(() => {
        this.idCopiedRecently = true;
        setTimeout(() => this.idCopiedRecently = false, 2500);
      });
    }
  }

  copyMetadataToClipboard(): void {
    if (this.viewModel?.metadata) {
      navigator.clipboard.writeText(this.viewModel.metadata).then(() => {
        this.metadataCopiedRecently = true;
        setTimeout(() => this.metadataCopiedRecently = false, 2500);
      });
    }
  }

  copyFaceDataToClipboard(): void {
    if (this.viewModel?.rekognitionFaceData) {
      navigator.clipboard.writeText(this.viewModel.rekognitionFaceData).then(() => {
        this.faceDataCopiedRecently = true;
        setTimeout(() => this.faceDataCopiedRecently = false, 2500);
      });
    }
  }

  downloadOriginal(): void {
    if (this.viewModel?.originalSrc) {
      this.api.getHeadOriginalDownloadUrl(this.viewModel?.id).subscribe({
        next: url => {
          window.open(url, '_blank');
        },
        error: err => {
          console.error(`Failed to download original: ${err}`);
          window.alert(`Downloading original failed`); // TODO: better error reporting
        }
      });
    }
  }

  include(): void {
    this.busy = true;
    firstValueFrom(this.api.includeHead(this.id!))
      .then(() => {
        this.refresh();
      })
      .catch(err => {
        console.error(`Failed to include head: ${err}`);
        window.alert(`Failed to include head`); // TODO: better error reporting
      })
      .finally(() => {
        this.busy = false;
      });
  }

  exclude(): void {
    this.busy = true;
    firstValueFrom(this.api.excludeHead(this.id!))
      .then(() => {
        this.refresh();
      })
      .catch(err => {
        console.error(`Failed to include head: ${err}`);
        window.alert(`Failed to include head`); // TODO: better error reporting
      })
      .finally(() => {
        this.busy = false;
      });
  }

  private refresh(): void {
    if (this.id) {
      this.api.getHead(this.id).subscribe({
        next: head => {
          const user = this.auth.user.value;
          if (user) {
            this.viewModel = new DetailViewModel(head, user);
          } else {
            this.router.navigate(['/login']);
          }
        },
        error: err => {
          console.error(`Failed to load head with ID ${this.id}, going back to overview: ${err}`);
          this.router.navigate(['/heads'], {replaceUrl: true});
        }
      });
    }
  }

  onVideoTimeUpdate(evt: any): void {
    if (!this.videoElement || !this.viewModel || !this.viewModel.boundingBoxes) {
      return;
    }
    const timeMillis: number = this.videoElement.nativeElement.currentTime * 1000;

    // look up the last valid bounding box
    let currentBoundingBox: TimestampedBoundingBox | null = null;
    for (const boundingBox of this.viewModel.boundingBoxes) {
      if (timeMillis > boundingBox.timestamp) {
        currentBoundingBox = boundingBox;
      }
    }

    // get ready for drawing
    const canvas = this.overlayElement!.nativeElement;
    const ctx = canvas.getContext('2d');
    if (ctx) {
      ctx.clearRect(0, 0, this.viewModel.width, this.viewModel.height);

      if (!this.drawBoundingBoxes) {
        return;
      }

      if (currentBoundingBox) {
        ctx.strokeStyle = '#ffffff';
        ctx.lineWidth = 1;
        ctx.strokeRect(
          currentBoundingBox.left * this.viewModel.width,
          currentBoundingBox.top * this.viewModel.height,
          currentBoundingBox.width * this.viewModel.width,
          currentBoundingBox.height * this.viewModel.height
        );
      }
      if (this.viewModel.boundingBoxUnion) {
        ctx.strokeStyle = '#0000ff';
        ctx.lineWidth = 1;
        const minX = this.viewModel.boundingBoxUnion[0];
        const minY = this.viewModel.boundingBoxUnion[1];
        const maxX = this.viewModel.boundingBoxUnion[2];
        const maxY = this.viewModel.boundingBoxUnion[3];
        ctx.strokeRect(
          minX * this.viewModel.width,
          minY * this.viewModel.height,
          (maxX - minX) * this.viewModel.width,
          (maxY - minY) * this.viewModel.height
        );
      }
    }
  }
}
