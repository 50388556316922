<div class="detail-container" *ngIf="viewModel else loading">

  <a [routerLink]="" (click)="goBack()">&lt;&lt; Back to gallery</a>

  <div class="headings">
    <h3>{{ viewModel.name }}</h3>
    <h4 class="copiable-text" (click)="copyIdToClipboard()">
      <ng-container *ngIf="idCopiedRecently else showId">
        Copied to clipboard!
      </ng-container>
      <ng-template #showId>
        Head ID: {{ viewModel.id }}
      </ng-template>
    </h4>
    <h4>
      {{ viewModel.deliveryDate }}
    </h4>
  </div>

  <div class="metadata">
    <kbd>{{ viewModel.state }}</kbd>&nbsp;&nbsp;
    {{ viewModel.originalDuration }} @ {{ viewModel.width }}x{{ viewModel.height }}px
  </div>

  <div class="labels">
    <span class="label" *ngFor="let occurrence of viewModel.labelOccurrences">{{ occurrence.nameWithConfidence }}</span>
  </div>

  <ng-container *ngIf="viewModel.h264Src else notEncodedYet">

    <div class="video-container" [style.width.px]="viewModel.width" [style.height.px]="viewModel.height">
      <canvas #overlay class="video-overlay" [width]="viewModel.width" [height]="viewModel.height">
        <!-- overlays will be drawn here -->
      </canvas>
      <video #video [src]="viewModel.h264Src" controls loop preload="auto"
             [width]="viewModel.width" [height]="viewModel.height"
             (timeupdate)="onVideoTimeUpdate($event)"
      >

      </video>
    </div>
    <div class="playback-options">
      <fieldset>
        <label for="draw_bounding_boxes">
          <input type="checkbox" id="draw_bounding_boxes" name="draw_bounding_boxes" [(ngModel)]="drawBoundingBoxes">
          Draw bounding boxes
        </label>
      </fieldset>
    </div>
  </ng-container>

  <ng-template #notEncodedYet>
    Video has not yet been converted to H.264.
  </ng-template>

  <div class="detail-actions">
    <button class="auto-width" [disabled]="busy" (click)="downloadOriginal()">Download original
      ({{viewModel.originalSize}})
    </button>
    <button class="auto-width secondary"
            [disabled]="busy || !viewModel.showCurationActions"
            *ngIf="viewModel.included"
            (click)="exclude()">
      Exclude
    </button>
    <button class="auto-width secondary"
            [disabled]="busy || !viewModel.showCurationActions"
            *ngIf="viewModel.excluded"
            (click)="include()">
      Include
    </button>
  </div>

  <div class="detail-metadata" *ngIf="viewModel.metadata">
    <details>
      <summary>ffprobe output</summary>
      <p *ngIf="metadataCopiedRecently else showMetadata">Copied to clipboard!</p>
      <ng-template #showMetadata>
        <p class="copiable-text" (click)="copyMetadataToClipboard()">Copy to clipboard</p>
      </ng-template>
      <div class="json">
        {{ viewModel.metadata }}
      </div>
    </details>
  </div>

  <div class="detail-metadata" *ngIf="viewModel.rekognitionFaceData">
    <details>
      <summary>Rekognition face detection data</summary>
      <p *ngIf="faceDataCopiedRecently else showMetadata">Copied to clipboard!</p>
      <ng-template #showMetadata>
        <p class="copiable-text" (click)="copyFaceDataToClipboard()">Copy to clipboard</p>
      </ng-template>
      <div class="json">
        {{ viewModel.rekognitionFaceData }}
      </div>
    </details>
  </div>

</div>

<ng-template #loading>
  <div class="detail-container">
    <progress id="progress" indeterminate="true"></progress>
  </div>
</ng-template>
