<div class="deliver-container">

  <a [routerLink]="" (click)="goBack()">&lt;&lt; Back to gallery</a>

  <div class="headings" *ngIf="deliverySubmitted else deliveryPending">
    <h3>ZIP file urls were submitted</h3>
    <h4>Data import and processing will take some time. Please be patient.</h4>
  </div>

  <ng-template #deliveryPending>

    <div class="headings">
      <h3>Deliver heads</h3>
      <h4>Enter one ZIP file URL per line</h4>
    </div>

    <p *ngIf="!authorizedForDelivery else authorized">Sorry, you are not authorized to use this function.</p>
    <ng-template #authorized>

      <textarea class="url-textarea" [(ngModel)]="urlList" *ngIf="!validating && !readyForDelivery"
                rows="10" spellcheck="false" placeholder="https://example.com/path/file.zip"></textarea>

      <div class="validated-urls" *ngIf="readyForDelivery">
        <div *ngFor="let url of validatedUrls">
          <mark>valid</mark>
          <small> {{ url.toString() }}</small>
        </div>
      </div>

      <progress *ngIf="validating || delivering"></progress>

      <p class="validation-errors" *ngIf="errorHtml" [innerHTML]="errorHtml"></p>

      <div class="actions">

        <div class="button-bar">
          <button class="secondary" *ngIf="readyForDelivery" (click)="cancelDelivery()">Cancel</button>
          <button *ngIf="readyForDelivery && validatedUrls" (click)="deliverUrls()">Deliver {{ validatedUrls.length }} ZIP
            files
          </button>
        </div>

        <button *ngIf="!readyForDelivery" (click)="validateUrls()">Validate URLs</button>
      </div>
    </ng-template>
    
  </ng-template>
</div>
