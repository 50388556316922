export class User {

  constructor(public username: string, public userId: string, public role: string) {
  }

  isAuthorizedForDelivery(): boolean {
    return [UserRoles.Studio.valueOf(), UserRoles.Root.valueOf()].includes(this.role);
  }
}

export enum UserRoles {
  Studio = 'studio',
  Director = 'director',
  Renderer = 'renderer',
  Root = 'root',
  Viewer = 'viewer',
}
