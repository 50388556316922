<div class="gallery-container">

  <div class="header">
    <span class="logotype">H E A D S</span>

    <div style="flex-grow: 1"><!-- spacer --></div>
    <div>
      <a routerLink="/deliver">Deliver heads</a> | <a routerLink="" (click)="signOut()">Sign out</a>
    </div>
  </div>

  <div class="result-stats" *ngIf="totalCount !== undefined">
    <p>Total # of heads: {{ totalCount }}</p>
    <p>
    <label for="show_excluded">
      <input type="checkbox" id="show_excluded" name="show_excluded" (change)="refresh()" [(ngModel)]="showExcluded">
      Show excluded
    </label>
    </p>
    <div class="label-filter">
      <span class="label" [class.inactive]="!labelFilter[1]"
            (click)="toggleFilter(labelFilter)"
            *ngFor="let labelFilter of labelFilters">
        {{ labelFilter[0] }}
      </span>
    </div>
    <div style="flex-grow: 1"><!-- spacer --></div>
    <p *ngIf="jobsRunning !== undefined">{{ jobsRunning }} active batch jobs</p>
  </div>
  <div class="gallery">
    <div *ngFor="let item of items" class="gallery-item" (click)="onHeadClicked(item)">
      <img [src]="item.previewSrc" [alt]="item.tooltip" [title]="item.tooltip" [class.excluded]="item.excluded">
      <div class="excluded-banner" *ngIf="item.excluded">EXCLUDED</div>
    </div>
  </div>

  <div class="load-more">
    <button (click)="loadMoreHeads()">Load more heads</button>
  </div>

</div>
