import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './components/app/app.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { DeliverComponent } from './components/deliver/deliver.component';
import { DetailComponent } from './components/detail/detail.component';
import { LoginComponent } from './components/login/login.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JwtModule } from '@auth0/angular-jwt';
import { environment } from '../environments/environment';
import { Router } from '@angular/router';
import { AuthenticationErrorInterceptor } from './interceptors/authentication-error-interceptor';
import { AuthService } from './services/auth.service';

@NgModule({
  declarations: [
    AppComponent,
    GalleryComponent,
    DeliverComponent,
    DetailComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: () => {
          return sessionStorage.getItem('accessToken');
        },
        allowedDomains: [new URL(environment.apiUrl).host],
        disallowedRoutes: [`${environment.apiUrl}/api/v1/auth/token`]
      },
    }),
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: (router: Router, auth: AuthService) => {
        return new AuthenticationErrorInterceptor(router, auth);
      },
      multi: true,
      deps: [Router, AuthService]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
