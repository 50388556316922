import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { VersionService } from '../../services/version.service';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  loginForm: FormGroup;

  busy = false;
  errorMessage = '';

  versionNumber: string;

  constructor(private router: Router,
              private auth: AuthService,
              private version: VersionService) {
    this.versionNumber = version.version;
    this.loginForm = new FormBuilder().nonNullable.group({
      username: '',
      password: ''
    });
  }

  doLogin() {
    if (!this.loginForm.valid) {
      return;
    }

    this.busy = true;
    this.auth.signIn(this.loginForm.value.username, this.loginForm.value.password)
      .then(() => {
        this.router.navigate(['/heads']);
      })
      .catch((err) => {
        this.errorMessage = err.toLocaleString();
      })
      .finally(() => {
        this.busy = false;
      })
  }
}
