import { Component } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-deliver',
  templateUrl: './deliver.component.html',
  styleUrls: ['./deliver.component.scss']
})
export class DeliverComponent {

  urlList = '';
  errorHtml?: string = undefined;
  validating = false;
  delivering = false;

  authorizedForDelivery = false;
  readyForDelivery = false;
  validatedUrls?: URL[] = undefined;
  deliverySubmitted = false;

  constructor(private api: ApiService,
              private auth: AuthService,
              private router: Router) {
    this.authorizedForDelivery = auth.user.value ? auth.user.value.isAuthorizedForDelivery() : false;
  }

  validateUrls(): void {

    // clear previous errors
    this.errorHtml = undefined;

    // local validation, attempt to parse each line as URL
    const lines = this.urlList.trim().split('\n').map(l => l.trim());
    const erroneousLines = lines.filter(line => {
      try {
        new URL(line);
        return false;
      } catch (TypeError) {
        return true;
      }
    });

    if (erroneousLines.length > 0) {
      this.errorHtml = 'Some lines contained errors (not URLs):<br>' + erroneousLines.join('<br>');
      return;
    }

    // local validation succeeded, now validate them server-side on AWS
    const promises = lines.map(line => {
      return firstValueFrom(this.api.validateDeliverUrl(line));
    });

    this.validating = true;
    Promise.all(promises)
      .then(messages => {
        const errorMessages = messages.filter(m => m !== null);
        if (errorMessages.length > 0) {
          this.errorHtml = `Some URLs could not be validated:<br>`;
          errorMessages.forEach(m => {
            this.errorHtml += `${m}<br>`
          });
          return;
        }

        // all ok
        this.errorHtml = undefined;
        this.validatedUrls = lines.map(l => new URL(l));
        this.readyForDelivery = true;
      })
      .catch(err => {
        if (err instanceof ErrorEvent) {
          // client-side error
          this.errorHtml = `Some URLs could not be validated: ${err}`;
        } else {
          // server-side error
          this.errorHtml = `Some URLs could not be validated: ${err.error.message}`;
        }

      })
      .finally(() => {
        this.validating = false;
      });
  }

  cancelDelivery() {
    this.readyForDelivery = false;
    this.validatedUrls = undefined;
    this.errorHtml = undefined;
  }

  deliverUrls() {
    if (this.validatedUrls === undefined || this.validatedUrls.length === 0) {
      return;
    }

    this.delivering = true;
    const promises = this.validatedUrls.map(url => {
      return firstValueFrom(this.api.deliver(url.toString()));
    });
    Promise.all(promises)
      .then(jobIds => {
        this.deliverySubmitted = true;
      })
      .catch((err) => {
        this.errorHtml = `Error while submitting delivery:<br>${err}`;
      })
      .finally(() => {
        this.delivering = false;
      });
  }

  goBack(): void {
    this.router.navigate(['/heads'], { replaceUrl: true });
  }
}
