import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { User } from '../models/user.model';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';

interface AccessTokenPayload {
  sub: string;
  head_role: string;
  head_user_id: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  /**
   * The authenticated user.
   */
  user = new BehaviorSubject<User | undefined>(undefined);

  private readonly accessTokenKey = 'accessToken';

  constructor(private api: ApiService,
              private jwtHelper: JwtHelperService) {
    const storedToken = sessionStorage.getItem(this.accessTokenKey);
    if (storedToken !== null) {
      try {
        if (!jwtHelper.isTokenExpired(storedToken)) {
          this.processAccessToken(storedToken);
        }
      } catch (e) {
        console.error(`Error while reading stored access token: ${e}`);
      }
    }
  }

  get isSignedIn(): boolean {
    return this.user.value !== undefined;
  }

  signIn(username: string, password: string): Promise<void> {
    return firstValueFrom(this.api.accessToken(username, password))
      .then(accessToken => {
        this.processAccessToken(accessToken);
        return Promise.resolve();
      })
      .catch(err => {
        if (err instanceof ErrorEvent) {
          // client-side error
          throw err;
        } else {
          // server-side error
          return Promise.reject(`Authentication failed: ${err.error.message}`);
        }
      });
  }

  signOut(): void {
    sessionStorage.removeItem(this.accessTokenKey);
    this.user.next(undefined);
  }

  private processAccessToken(jwt: string): void {
    const payload = this.jwtHelper.decodeToken<AccessTokenPayload>(jwt);
    console.log(`Logged in as user ${payload.sub}`);
    sessionStorage.setItem(this.accessTokenKey, jwt);
    this.user.next(new User(payload.sub, payload.head_user_id, payload.head_role));
  }
}
