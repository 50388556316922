import { Head } from '../models/head.model';
import { formatSize } from '../utils/format-utils';
import { User, UserRoles } from '../models/user.model';
import * as moment from 'moment';
import { getFormattedDate } from '../utils/date-util';
import { LabelOccurrenceViewModel } from './label-occurrence.viewmodel';


export class TimestampedBoundingBox {
  top: number;
  left: number;
  width: number;
  height: number;

  // see: https://docs.aws.amazon.com/rekognition/latest/APIReference/API_FaceDetail.html
  constructor(public timestamp: number, faceDetail: any) {
    this.top = faceDetail['BoundingBox']['Top'];
    this.left = faceDetail['BoundingBox']['Left'];
    this.width = faceDetail['BoundingBox']['Width'];
    this.height = faceDetail['BoundingBox']['Height'];
  }
}

export class DetailViewModel {

  id: string;
  name: string;
  state: string;
  included: boolean;
  excluded: boolean;
  originalSrc: string;
  originalSize: string;
  originalDuration: string;
  width: number;
  height: number;
  h264Src: string;
  metadata: string;
  rekognitionFaceData: string;
  labelOccurrences: LabelOccurrenceViewModel[];
  busy = false;
  deliveryDate: string;
  showCurationActions = false;
  boundingBoxes: TimestampedBoundingBox[] | undefined;
  boundingBoxUnion: number[] | undefined;

  constructor(head: Head, authenticatedUser: User) {
    this.id = head.head_id;
    this.deliveryDate = getFormattedDate(moment(head.creation_time));
    this.name = head.head_name;
    this.state = head.head_state;
    this.included = head.head_state === 'included';
    this.excluded = head.head_state === 'excluded';
    this.originalSrc = head.head_original_src;
    this.originalSize = formatSize(head.head_original_size);
    this.originalDuration = `${head.head_original_duration.toFixed(1)} seconds`;
    this.h264Src = head.head_h264_src;
    this.width = head.head_original_width;
    this.height = head.head_original_height;
    this.metadata = JSON.stringify(head.head_original_metadata, null, '\t');
    if (head.rekognition_face_detection_data) {
      this.rekognitionFaceData = JSON.stringify(head.rekognition_face_detection_data, null, '\t');
      const faces = head.rekognition_face_detection_data['Faces'];
      this.boundingBoxes = [];
      for (const face of faces) {
        const boundingBox = new TimestampedBoundingBox(face['Timestamp'], face['Face']);
        this.boundingBoxes.push(boundingBox);
      }
    } else {
      this.rekognitionFaceData = 'Rekognition face detection has not run yet. Please be patient.'
    }
    this.boundingBoxUnion = head.rekognition_face_bounding_box_union;
    this.showCurationActions = authenticatedUser.role === UserRoles.Director || authenticatedUser.role === UserRoles.Root;
    if (head.label_occurrences) {
      this.labelOccurrences = head.label_occurrences.map(lo => new LabelOccurrenceViewModel(lo));
    } else {
      this.labelOccurrences = [];
    }
  }
}
