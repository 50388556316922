import { HeadSearchView } from '../models/head-search-view.model';

export class GalleryItemViewModel {

  id: string;
  previewSrc: string;
  previewWidth: number;
  previewHeight: number;
  tooltip: string;
  excluded: boolean;

  constructor(head: HeadSearchView) {
    this.id = head.head_id
    this.previewSrc = head.head_gif_preview_src;
    this.previewWidth = head.head_gif_preview_width;
    this.previewHeight = head.head_gif_preview_height;
    this.tooltip = head.head_name;
    this.excluded = head.head_state === 'excluded';
  }
}
