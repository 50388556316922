import { LabelOccurrence } from '../models/label-occurrence.model';

export class LabelOccurrenceViewModel {

  nameWithConfidence: string;

  constructor(occurrence: LabelOccurrence) {
    const percent = (occurrence.confidence * 100).toFixed(0);
    this.nameWithConfidence = `${occurrence.label.label_name} (${percent}%)`;
  }
}
