import { AfterViewInit, Component, OnInit } from '@angular/core';
import { GalleryItemViewModel } from '../../viewmodels/gallery-item.viewmodel';
import { ApiService } from '../../services/api.service';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { HeadsQueryService } from '../../services/heads-query.service';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit, AfterViewInit {

  items: GalleryItemViewModel[] = []
  totalCount?: number = undefined;
  jobsRunning?: number = undefined;

  labelFilters: [string, boolean][] = [
    ['male', false],
    ['female', false],
    ['beard', false],
    ['mustache', false],
    ['eyeglasses', false],
    ['sunglasses', false]
  ];

  showExcluded = false;

  private readonly scrollPositionKey = 'galleryScrollPosition';

  constructor(private router: Router,
              private apiService: ApiService,
              private headsQueryService: HeadsQueryService,
              private viewportScroller: ViewportScroller,
              private authService: AuthService) {
  }

  ngOnInit(): void {
    this.loadJobsRunning();
    this.updateHeads();
    this.updateFilters();
  }

  ngAfterViewInit(): void {
    // if we haven't loaded heads yet, load the initial batch now
    if (this.headsQueryService.heads === undefined) {
      this.refresh();
    } else {

      // restore scroll position
      const scrollPositionStr = sessionStorage.getItem(this.scrollPositionKey);
      if (scrollPositionStr !== null) {
        const scrollPosition = JSON.parse(scrollPositionStr);
        this.viewportScroller.scrollToPosition(scrollPosition);
        sessionStorage.removeItem(this.scrollPositionKey);
      }
    }
  }

  loadMoreHeads(): void {
    this.headsQueryService.loadMore().subscribe({
      next: value => {
        this.updateHeads();
      },
      error: err => {
        this.handleLoadError(err);
      }
    });
  }

  loadJobsRunning(): void {
    this.apiService.getActiveBatchJobs().subscribe(jobs => this.jobsRunning = jobs.length);
  }

  refresh(): void {
    this.setQueryParameters();
    this.headsQueryService.refresh().subscribe({
      next: value => {
        this.updateHeads();
      },
      error: err => {
        this.handleLoadError(err);
      }
    });
  }

  toggleFilter(labelFilter: [string, boolean]) {
    labelFilter[1] = !labelFilter[1];
    this.refresh();
  }

  onHeadClicked(item: GalleryItemViewModel) {
    // save scroll position
    sessionStorage.setItem(this.scrollPositionKey, JSON.stringify(this.viewportScroller.getScrollPosition()));
    this.router.navigate(['heads', item.id]);
  }

  signOut() {
    this.authService.signOut();
    this.router.navigate(['/login']);
  }

  private updateHeads(): void {
    if (this.headsQueryService.heads) {
      this.items = this.headsQueryService.heads.map(h => new GalleryItemViewModel(h));
      this.totalCount = this.headsQueryService.totalCount;
    }
  }

  private updateFilters(): void {
    const enabledLabels = this.headsQueryService.getQueryLabels();
    for (const labelFilter of this.labelFilters) {
      labelFilter[1] = enabledLabels.includes(labelFilter[0]);
    }
    this.showExcluded = this.headsQueryService.getQueryStates().includes('excluded');
  }

  private setQueryParameters() {
    const states = ['included'];
    if (this.showExcluded) {
      states.push('excluded');
    }
    const desiredLabels = this.labelFilters.filter(lf => lf[1]).map(lf => lf[0]);
    this.headsQueryService.setQueryLabels(desiredLabels);
    this.headsQueryService.setQueryStates(states);
  }

  private handleLoadError(err: any) {
    if (err instanceof ErrorEvent) {
      // client-side error
      window.alert(`Failed to load heads: ${err}`);
    } else {
      // server-side error
      window.alert(`Failed to load heads: ${err.error.message}`);
    }
  }
}
